import { Box, Disc, Home, Layers } from "react-feather";
import { default as blog1, default as blog9 } from "../assets/blog/01.jpg";
import blog2 from "../assets/blog/02.jpg";
import blog3 from "../assets/blog/03.jpg";
import blog4 from "../assets/blog/04.jpg";
import blog5 from "../assets/blog/05.jpg";
import blog6 from "../assets/blog/06.jpg";
import blog7 from "../assets/blog/07.jpg";
import blog8 from "../assets/blog/08.jpg";
import one from "../assets/png/1.png";
import alQuran from "../assets/png/alQuran.jpg";
import contact_ from "../assets/png/contact_.png";
import tasbeeh from "../assets/png/tasbeeh.png";
import qibla from "../assets/png/qibla.jpg";
import islamic from "../assets/png/islamic.jpg";
import agora from "../assets/png/agora.png";
import down from "../assets/png/down-arrow.png";
import duaChild from "../assets/png/duaChild.png";
import duaimage from "../assets/png/duaimage.png";
import duasbg from "../assets/png/duasbg.png";
import editx from "../assets/png/editx.png";
import expe from "../assets/png/expe.png";
import facebook from "../assets/png/fb_zikr.png";
import hajGuide from "../assets/png/hajGuide.png";
import hajIntro from "../assets/png/hajIntro.png";
import hajjImage from "../assets/png/hajjImage.png";
import hajjUmmrah1 from "../assets/png/hajjUmmrah1.png";
import hajView from "../assets/png/hajView.png";
import hands from "../assets/png/hands.png";
import lesser from "../assets/png/less-than.png";
import praying from "../assets/png/praying.png";

import Loc_ from "../assets/png/Loc_.png";
import locationicon from "../assets/png/locationicon.png";
import mail_ from "../assets/png/mail_.png";
import mockup from "../assets/png/Mockup.png";
import payment from "../assets/png/payment.jpg";
import zoom from "../assets/png/zoom.png";

import confirmed from "../assets/png/confirmed.png";
import loginbg from '../assets/png/login-bg.jpeg'
import massage from "../assets/png/message.png";
import more from "../assets/png/More.png";
import moreicon from "../assets/png/moreicon.png";
import namesAllah from "../assets/png/names.jpg";
import names from "../assets/png/names.png";
import prayerBg from "../assets/png/prayerBg.jpg";
import profile1 from "../assets/png/profile1.png";
import profile2 from "../assets/png/profile2.png";
import profileavatar from "../assets/png/profileAvatar.png";
import image1 from "../assets/png/qFeature.png";
import image2 from "../assets/png/qFeature1.png";
import image3 from "../assets/png/qFeature2.png";
import rated from "../assets/png/rated.png";
import rating from "../assets/png/rating.png";
import rehman from "../assets/png/rehman.png";
import greater from "../assets/png/right.png";
import social1 from "../assets/png/social1.png";
import social2 from "../assets/png/social2.png";
import zikrProfile from "../assets/png/zikrProfile.png";
import tasbeehbanner from "../assets/png/tasbeeh.jpg";
import calender_ from "../assets/png/calender_.png";
import calender__ from "../assets/png/calender__.png";
import tasbeehMain from "../assets/png/tasbeehmain.png";
import tasbeehbgmain from "../assets/png/tassbeehbgmain.png";
import exper from "../assets/png/expe.png";
import star from "../assets/png/rate.png";
import reciteTasbeeh from "../assets/png/reciteTasbeeh.png";
import tasbeehbg from "../assets/png/tasbeehbg.png";
import tik_tok from "../assets/png/tik_tok.png";
import top from "../assets/png/top.png";
import umrahGuide from "../assets/png/umrahGuide.png";
import upper from "../assets/png/upper.png";
import two from "../assets/png/2.png";
import four from "../assets/png/4.png";
import allDuas from "../assets/png/allDuas.png";
import asar from "../assets/png/asar.png";
import bismillahbg from "../assets/png/bismillahbg.png";
import duaBg from "../assets/png/qibla.jpg";
import editIcon from "../assets/png/editIcon.png";
import setting from "../assets/png/setting.jpg";
import bgMobile from "../assets/png/Visuals.png";
import x from "../assets/png/x.png";
import zikr_profile from "../assets/png/zikr_profile.png";
import access_1 from "../assets/svg/access_1.svg";
import access_2 from "../assets/svg/access_2.svg";
import mosque from "../assets/svg/mosque.svg";
import arrowrightgray from "../assets/svg/arrowrightgray.svg";
import pencilEdit from "../assets/svg/penciledit.svg";

import paymentAdd from "../assets/png/paymentAdd.png";
import qiblaDirection from "../assets/png/qiblaDirection.png";

import avatarman from "../assets/svg/avatarman.svg";

import fajar from "../assets/png/fajar.png";
import kitab from "../assets/png/kitab.png";
import learnQ from "../assets/png/learnQ.png";
import magrib from "../assets/png/magrib.png";
import Vector from "../assets/png/Vector.png";
import zikrLogo from "../assets/png/zikrLogo.jpeg";
import dashboardtutor from '../assets/png/dashboardtutor.png'
import users from '../assets/png/users.png'
import arrowgrow from '../assets/png/arrowgrow.png'
import arrowless from '../assets/png/arrowless.png'
import chartdots from '../assets/png/chart-dots-2.png'
import session from '../assets/png/session.png'
const menuItems = [
  {
    icon: <Home size={20} />,
    label: "Home",
    path: "/",
    exact: true,
  },
  {
    label: "Patient",
    icon: <Box size={20} />,
    submenu: [
      {
        label: "Patient List",
        path: "/list-patient",
        icon: <Disc size={20} />,
        exact: false,
      },
      {
        label: "Checked Patient",
        path: "/checked-patient",
        icon: <Disc size={20} />,
        exact: false,
      },
    ],
  },
  {
    label: "Patient Info",
    icon: <Layers size={20} />,
    submenu: [
      {
        label: "Manage Patient",
        icon: <Disc size={20} />,
        path: "/manage-patient",
        exact: false,
      },
    ],
  },
];

export {
  dashboardtutor,
  loginbg,
  access_1,
  users, arrowgrow, arrowless, chartdots, session,
  access_2,
  allDuas,
  alQuran,
  arrowrightgray,
  asar,
  avatarman,
  bgMobile,
  bismillahbg,
  blog1,
  blog2,
  blog3,
  blog4,
  blog5,
  blog6,
  blog7,
  blog8,
  blog9,
  confirmed,
  contact_,
  down,
  duaBg,
  duaChild,
  duaimage,
  duasbg,
  editIcon,
  editx,
  expe,
  exper,
  facebook,
  fajar,
  four,
  greater,
  hajGuide,
  hajIntro,
  hajjImage,
  hajjUmmrah1,
  hajView,
  hands,
  image1,
  image2,
  calender_,
  calender__,
  image3,
  kitab,
  learnQ,
  lesser,
  Loc_,
  locationicon,
  magrib,
  mail_,
  massage,
  menuItems,
  mockup,
  more,
  moreicon,
  mosque,
  qiblaDirection,
  names,
  namesAllah,
  one,
  payment,
  paymentAdd,
  pencilEdit,
  prayerBg,
  agora,
  islamic,
  profile1,
  profile2,
  praying,
  profileavatar,
  rated,
  rating,
  reciteTasbeeh,
  rehman,
  setting,
  social1,
  social2,
  star,
  tasbeeh,
  tasbeehbanner,
  tasbeehbg,
  tasbeehbgmain,
  tasbeehMain,
  tik_tok,
  top,
  two,
  umrahGuide,
  qibla,
  upper,
  Vector,
  x,
  zikr_profile,
  zikrLogo,
  zikrProfile,
  zoom,
};
