import React, { useEffect, useState } from "react";
// import { asar, fajar, magrib, prayerBg } from '../icons/icon'
import { Breadcrumb } from "react-bootstrap";
// import TimeCard from '../common/timeCard'
import Box from "@mui/material/Box";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import { allDuas, bismillahbg, duaBg, kitab, profile1, Vector } from "../icons/icon";
import Footer from "./footer";
import { axiosInstanceApi } from "../api/axiosInstance";
import { CircularProgress } from "@mui/material";
import moment from "moment";
gsap.registerPlugin(ScrollTrigger);
const Meetings = () => {
    const [link, setlink] = useState();
    const [duas, setDuas] = useState([])
    const [sessions, setSessions] = useState([])
    const [loading, setLoading] = useState(false)
    const [activeTab, setActiveTab] = useState("pending");

    const tabs = [
        { key: "pending", title: "Pending", count: 13 },
        { key: "started", title: "Started", count: 5 },
        { key: "completed", title: "Completed", count: 5 },

    ];
    useEffect(() => {
        setLoading(true)
        axiosInstanceApi(`session/tutor/${activeTab}/`).then((res) => {
            if (res) {
                console.log(res?.data?.data, "res")
                setLoading(false)
                setSessions(res?.data?.data)
            }
        }).catch((err) => {
            setLoading(false)

        })
    }, [activeTab]);

    const handlePendingApi = (item) => {
        console.log(item?._id, "item")
        axiosInstanceApi.put(`session/update/started/${item?._id}`).then((res) => {
        }).catch((err) => { })
    }

    const handleStartedApi = (item) => {
        axiosInstanceApi.put(`session/update/completed/${item?._id}`).then((res) => {
        }).catch((err) => { })
    }

    useEffect(() => {
        var tl = gsap.timeline();
        var scrollTrigger1 = gsap.timeline({
            scrollTrigger: {
                trigger: "#bismillah_img",
                start: "top 80%",
                end: "bottom 100%",
                scrub: true,
            },
        });
        var scrollTrigger2 = gsap.timeline({
            scrollTrigger: {
                trigger: "#left-tabs-example",
                start: "30% 50%",
                end: "50% 50%",
                scrub: true,

            },
        });
        tl.fromTo(
            "#duas_head",
            {
                x: 200,
                opacity: 0,
            },
            {
                x: 0,
                opacity: 1,
                duration: 1.5,
            }
        );
        tl.fromTo(
            "#duas",
            {
                x: -500,
                opacity: 0,
            },
            {
                x: 0,
                opacity: 1,
                duration: 1.5,
            },
            0
        );
        tl.fromTo(
            "#item",
            {
                y: 50,
                opacity: 0,
            },
            {
                y: 0,
                opacity: 1,
                duration: 1,
            }
        );
        scrollTrigger1.fromTo(
            "#bismillah_img",
            {
                scale: 1.5,
                opacity: 0,
            },
            {
                scale: 1,
                duration: 1,
                opacity: 1,
            }
        );
        scrollTrigger2.fromTo(
            "#child_one",
            {
                x: -500,
            },
            {
                x: 0,
            }
        )
        scrollTrigger2.fromTo(
            "#child_two",
            {
                x: 1000,
            },
            {
                x: 0,
            }
        )
    }, []);

    return (
        <main className="2xl:container grid mx-auto bg_brown_shade">
            <div className="relative min-h-[13rem]">
                <img
                    alt=""
                    src={duaBg}
                    className="md:h-auto h-[13rem] "
                    style={{
                        objectFit: "cover",

                    }}
                />
                {/* <img loading="lazy" src={duaBg} alt="prayer" className="md:h-auto h-[13rem]" /> */}
                <div className="absolute top-36 md:left-20 sm:left-16 left-9">
                    <div className="flex flex-col gap-0  text-white">
                        <p id="duas_head" className="gilroy-bold md:text-4xl text-md mb-0">
                            Meetings History
                        </p>
                    </div>
                </div>
            </div>
            <div className=" px-8 py-7 overflow-hidden">
                <Breadcrumb style={{ color: "white" }}>
                    <Breadcrumb.Item
                        className="gilroy-medium"
                        id="item"
                        style={{ color: "#AA8555", textDecoration: "none" }}
                        href="/"
                    >
                        Home
                    </Breadcrumb.Item>
                    <Breadcrumb.Item
                        className="gilroy-medium text-white opacity-45"
                        id="item"
                        active
                    >
                        Sessions History
                    </Breadcrumb.Item>
                </Breadcrumb>
                <div className="">

                    <div className="card bg-transparent py-5 border-1 border-[#404040] ">

                        <div id="duas" className="flex flex-col gap-3 mb-16 items-center ">
                            <h4 className="text-white gilroy-bold md:text-[37px] text-[25px] md:pb-4 pb-1 md:text-start text-center">
                                Meeting History
                            </h4>
                            <p className="text-white md:w-[40rem] text-center md:text-[16px] px-3 text-[13px]">
                                The session history reflects the different stages of progress, whether it’s Started, Pending, or Completed. When a session is Started, it indicates that the task or activity has begun, signaling the user's active participation                    </p>

                        </div>
                        <div className="flex justify-center">

                            <div className="md:w-[500px] sm:w-[500px] xss:w-[500px] w-full xss:px-0 px-4 ">
                                <div className="flex gap-2 bg-white rounded-md mt-3 overflow-hidden shadow-md">
                                    {tabs.map((tab) => (
                                        <div
                                            key={tab.key}
                                            className={`flex-1 text-center py-[.6rem] cursor-pointer ${activeTab === tab.key
                                                ? "bg-[#caa472] text-white"
                                                : "bg-white text-black"
                                                }`}
                                            onClick={() => setActiveTab(tab.key)}
                                        >
                                            <p className="text-[15px] gilroy-bold">{tab.title}</p>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                        {
                            loading ? (
                                <div className="flex justify-center items-center h-full mt-5">
                                    <CircularProgress
                                        style={{ color: "white" }}
                                        size={22}
                                        className="text_white"
                                    />
                                </div>
                            ) :
                                (
                                    <div className="row md:px-5  px-2 mt-5 mx-2 ">
                                        {sessions?.length > 0 ? sessions?.map((item, index) => {
                                            return (
                                                <div className="col-lg-6 col-12 my-2" key={index}>
                                                    <div className="bg-[#2A271E] text-white rounded-lg shadow-md p-4 max-w-full">
                                                        {/* Left section */}
                                                        <div className="flex md:flex-row flex-col justify-between items-center">
                                                            <div className="flex flex-col gap-2">
                                                                <div className="flex items-center space-x-4">
                                                                    {/* Tutor Image */}
                                                                    <img
                                                                        src={profile1} // Replace with actual image
                                                                        alt="Tutor"
                                                                        className="w-14 h-14 rounded-full"
                                                                    />
                                                                    {/* Tutor Info */}
                                                                    <div>
                                                                        <h2 className="text-lg font-semibold">{item?.to_id?.name}</h2>
                                                                        <div className="flex items-center space-x-2">
                                                                            <p className="text-sm">{item?.to_id?.type}</p>
                                                                            <p className="text-sm">{`$ ${item?.amount}`}</p>
                                                                            <div className="flex items-center space-x-1">
                                                                                <span className="text-yellow-400">★</span>
                                                                                <span className="text-sm">4.8/5</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="p-2 flex gap-2">
                                                                    <button className="bg-[#7F6542] text-white text-xs font-bold py-1 px-4 rounded-md mt-2">
                                                                        {item?.status === 'pending'
                                                                            ? 'pending'
                                                                            : item?.status === 'completed'
                                                                                ? 'completed'
                                                                                : 'started'}

                                                                    </button>
                                                                    {/* <button
                                                                        onClick={() => {
                                                                            if (item?.status === 'pending') {
                                                                                // Call API for 'pending' status
                                                                                handlePendingApi(item);
                                                                            } else if (item?.status === 'started') {
                                                                                // Call API for 'started' status
                                                                                handleStartedApi(item);
                                                                            }
                                                                        }}
                                                                        className={`${item?.status === 'pending'
                                                                            ? 'bg-green-800'
                                                                            : item?.status === 'started'
                                                                                ? 'bg-yellow-500'
                                                                                : item?.status === 'completed' ? "bg-transparent" : 'bg-[#7F6542]'
                                                                            } text-white text-xs font-bold py-1 px-4 rounded-md mt-2`}
                                                                    >
                                                                        {item?.status === 'pending'
                                                                            ? 'Start meeting'
                                                                            : item?.status === 'completed'
                                                                                ? ''
                                                                                : 'complete'}

                                                                    </button> */}
                                                                </div>
                                                            </div>
                                                            <div className="d-flex md:flex-col gap-3 md:justify-start md:gap-0 xss:justify-between xss:flex-wrap ">
                                                                <p className="text-sm pb-1">1 Hour Session</p>
                                                                <p className="text-sm pb-1">{moment(item?.createdAt).format('YYYY-DD-MM')}</p>
                                                                <p className="text-sm">{item?.bookedSlot}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        }) : (
                                            <div className="text-red-800 text-[12px]">
                                                <p className="text-lg text-center">No sessions available</p>
                                            </div>)}
                                    </div>

                                )

                        }

                    </div>
                </div>

            </div>

            <div className="mt-3">
                <Footer />
            </div>
        </main >
    );
};

export default Meetings;

