/* eslint-disable no-unused-vars */
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import React, { lazy } from "react";
import { useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";
import SuccessPage from '../payment/successPage';
import TutorRoutes from '../redux/auth/tutorRoutes';
import StudentRoutes from '../redux/auth/studentRoutes';
import Sessions from '../pages/sessions';
import Meetings from '../pages/meetings';
const ForgotPassword = lazy(() => import("../auth/forgotPassword"));
const Login = lazy(() => import("../auth/login"));
const Register = lazy(() => import("../auth/register"));
const RegisterTutor = lazy(() => import("../auth/registerTutor"));
const SendCode = lazy(() => import("../auth/sendCode"));
const SetPassword = lazy(() => import("../auth/setPassword"));
const VerifyCode = lazy(() => import("../auth/verifyCode"));
const VerifyOtp = lazy(() => import("../auth/verifyOtp"));
const BookingForm = lazy(() => import("../common/bookingForm"));
const SidebarLayout = lazy(() => import("../layout/sidebarLayout"));
const AllTutors = lazy(() => import("../pages/allTutors"));
const ChoosePayment = lazy(() => import("../pages/choosePayment"));
const DashboardTutor = lazy(() => import("../pages/dashboardTutor"));
const Duas = lazy(() => import("../pages/duas"));
const HajjUmrah = lazy(() => import("../pages/hajjUmrah"));
const HajjUmrahDetail = lazy(() => import("../pages/hajjUmrahDetail"));
const Home = lazy(() => import("../pages/home"));
const Information = lazy(() => import("../pages/information"));
const IslamicCalender = lazy(() => import("../pages/islamicCalender"));
const Learn = lazy(() => import("../pages/learn"));
const LearnQuran = lazy(() => import("../pages/learnQuran"));
const Names = lazy(() => import("../pages/names"));
const NoMatch = lazy(() => import("../pages/noMatch"));
const PaymentForm = lazy(() => import("../pages/paymentForm"));
const Prayers = lazy(() => import("../pages/prayers"));
const ProfileDetail = lazy(() => import("../pages/profileDetail"));
const ProfileSetting = lazy(() => import("../pages/profileSetting"));
const Qibla = lazy(() => import("../pages/qibla"));
const ReciteTasbeeh = lazy(() => import("../pages/reciteTasbeeh"));
const Schedule = lazy(() => import("../pages/schedule"));
const SessionConfirm = lazy(() => import("../pages/sessionConfirm"));
const Tasbeeh = lazy(() => import("../pages/tasbeeh"));
const ZoomMeeting = lazy(() => import("../pages/ZoomMeeting "));
const Payment = lazy(() => import('../payment'));
const PrivateRoutes = lazy(() => import("../redux/auth/privateRoutes"));
const PublicRoutes = lazy(() => import("../redux/auth/publicRoutes"));
const Messages = lazy(() => import("./../pages/chat/message"));
const Landing = lazy(() => import("../pages/landing"));

const Routing = () => {
  const role = window.localStorage.getItem('role');
  const information = useSelector((state) => state.auth.isInformation);
  const stripePromise = loadStripe('pk_test_51PvmvCFIO6DNkeIVDehX1OzQW2rYe6KOEo21fwd8aJ1mVdV4FCFGScfETK6sMwtsF6nWGcL0sR0hoUw8ImyQRuhQ00FL0EX6WZ');
  return (
    <>
      <Routes>

        <Route element={<PrivateRoutes />}>
          {
            role === 'tutor' && !information ?
              <Route path="/information" element={<Information />}></Route> : role === 'student' ?

                <Route path="/home" element={<Home />}></Route> : null
          }
          <Route element={<TutorRoutes />}>
            <Route path="/dashboard" element={<DashboardTutor />}></Route>
            <Route path="/meetings" element={<Meetings />}></Route>

            <Route path="/zoom-meeting" element={<ZoomMeeting />}></Route>
            {/* <Route path="/profile-setting" element={<ProfileSetting />}></Route> */}

          </Route>
          <Route element={<StudentRoutes />}>
            <Route path="/landing" element={<Landing />}></Route>
            <Route path="/all-tutors" element={<AllTutors />}></Route>
            <Route path="/prayers" element={<Prayers />}></Route>
            <Route path="/duas" element={<Duas />}></Route>
            <Route path="/sessions" element={<Sessions />}></Route>


            <Route path="/qibla" element={<Qibla />}></Route>
            <Route path="/islamic-calender" element={<IslamicCalender />}></Route>
            <Route path="/choose-payment" element={<ChoosePayment />}></Route>
            <Route path="/session-created" element={<SessionConfirm />}></Route>
            <Route path="/schedule" element={<Schedule />}></Route>
            <Route path="/hajj" element={<HajjUmrah />}></Route>
            <Route path="/names" element={<Names />}></Route>
            <Route path="/learn-Quran" element={<LearnQuran />}></Route>
            <Route path="/learn" element={<Learn />}></Route>
            <Route path="/tasbeeh" element={<Tasbeeh />}></Route>
            <Route path="/booking-form/?" element={<BookingForm />}></Route>
            <Route path="/recite-tasbeeh" element={<ReciteTasbeeh />}></Route>
            <Route path="/sidebar-layout" element={<SidebarLayout />}></Route>
            <Route path="/payment" element={<Payment />}></Route>
            <Route path="/success" element={<SuccessPage />}></Route>
          </Route>
          <Route path="/profile-setting" element={<ProfileSetting />}></Route>

          {/* <Route path="/all-tutors" element={<AllTutors />}></Route> */}

          <Route path="/chat/:id?" element={<Messages />}></Route>
          <Route path="/detail-page/:id" element={<HajjUmrahDetail />}></Route>

          <Route path="/profile" element={<ProfileDetail />}></Route>
          <Route path="/payment-screen" element={
            <Elements stripe={stripePromise}>
              <PaymentForm />  {/* PaymentForm is wrapped here */}
            </Elements>
          }></Route>

          <Route path="*" element={<NoMatch />} />
        </Route>
        <Route element={<PublicRoutes />}>
          <Route path="*" element={<NoMatch />} />
          <Route path="login" element={<Login />} />
          <Route path="register" element={<Register />} />
          <Route path="/send-code" element={<SendCode />} />
          <Route path="/verify-code" element={<VerifyCode />} />
          <Route path="/" element={<Landing />}></Route>
          <Route path="register-tutor" element={<RegisterTutor />} />
          <Route path="forgot-password" element={<ForgotPassword />} />
          <Route path="verify-otp" element={<VerifyOtp />} />
          <Route path="reset-password" element={<SetPassword />} />

          {/* <Route path="signup" element={<Signup />} />
                    <Route path="code-verify" element={<VerifyCode />} /> */}
        </Route>
      </Routes>
    </>
  );
};

export default Routing;
