/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
import { Nav, Navbar, Offcanvas } from "react-bootstrap";
import { Link, NavLink, useNavigate } from "react-router-dom";
// import RequestModal from '../pages/requestModal';
import "./navbar.css";
// import './NavHeader.css'; // Import the CSS file
// import { arrowdown, deal_Logo, menuicon } from '../icons/icon';
import { Menu as MenuIcon } from "react-feather";
import { ArrowDropDown, SearchRounded } from "@mui/icons-material";
import { message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { locationicon, more, profileavatar, zikrLogo } from "../icons/icon";
import { getInformationData_, setLogin, setUserData } from "../redux/loginForm";
// import { Menu, Transition } from '@headlessuireact';
import { setInformation } from "../redux/loginForm";
const NavHeader = () => {
  const role = window.localStorage.getItem('role');
  const userData = useSelector(state => state?.auth?.userData)

  const information = useSelector((state) => state.auth.isInformation);
  console.log(role, "role")
  console.log(userData, "userData")
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [selectedLink, setSelectedLink] = useState("1");
  const [show, setShow] = useState(false);
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [open, setopen] = useState(false);
  const isLogin = useSelector((state) => state.auth.isLogin);
  const [isScrolled, setIsScrolled] = useState(false);
  const [isFeaturesDropdownVisible, setIsFeaturesDropdownVisible] =
    useState(false);
  const [isPlansDropdownVisible, setIsPlansDropdownVisible] = useState(false);
  const [isProfileDropdownVisible, setIsProfileDropdownVisible] =
    useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };
  const handleLogout = () => {
    dispatch(setLogin(false));
    navigate("/");
    localStorage.removeItem('favourites');
    window.localStorage.removeItem("role");
    if (information) {
      dispatch(setInformation(true))
    }
    else {
      dispatch(setInformation(false))

    }
    dispatch(getInformationData_(null))
    dispatch(setUserData(null));
    window.localStorage.removeItem('zikr-web-token')
    message.success("You have been successfully logged out");
  };

  const registerRole = (role) => {
    window.localStorage.setItem("role", role);
  };

  // useEffect(() => {
  //   const handleScroll = () => {
  //     const currentScrollPos = window.scrollY;
  //     const isScrollingUp = currentScrollPos > prevScrollPos;
  //     setPrevScrollPos(currentScrollPos);

  //     if (isScrollingUp) {
  //       setIsScrolled(true);
  //     } else {
  //       setIsScrolled(false);
  //     }
  //   };

  //   window.addEventListener("scroll", handleScroll);

  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, [prevScrollPos]);

  // const prevScrollY = useRef(0);

  // useEffect(() => {
  //   const handleScroll = () => {
  //     const navbar = document.getElementById("navbar");
  //     const scrollPosition = window.scrollY;
  //     if (scrollPosition > prevScrollY.current) {
  //       navbar.style.top = "-80px";
  //     } else {
  //       navbar.style.top = "0";
  //     }
  //     if (
  //       document.body.scrollTop > 100 ||
  //       document.documentElement.scrollTop > 100
  //     ) {
  //       navbar.classList.add("nav_shadow");
  //     } else {
  //       navbar.classList.remove("nav_shadow");
  //     }
  //     prevScrollY.current = scrollPosition;
  //   };
  //   window.addEventListener("scroll", handleScroll);
  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, []);
  function ScrollHandler() {
    const prevScrollY = useRef(0);
    useEffect(() => {
      const handleScroll = () => {
        const navbar = document.getElementById("navbar");
        const scrollPosition = window.scrollY;
        if (scrollPosition > prevScrollY.current) {
          navbar.style.top = "-80px";
        } else {
          navbar.style.top = "0";
        }
        if (
          document.body.scrollTop > 100 ||
          document.documentElement.scrollTop > 100
        ) {
          navbar.classList.add("nav_shadow");
        } else {
          navbar.classList.remove("nav_shadow");
        }
        prevScrollY.current = scrollPosition;
      };
      window.addEventListener("scroll", handleScroll);
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }, []); // Empty dependency array means this effect runs once after the initial render

    return null;
  }
  const navItemsTutor = [
    { id: "1", items: "Information", path: "/information" },
    { id: "2", items: "Dashboard", path: "/dashboard" },
    { id: "5", items: "Meetings", path: "/meetings" },
    { id: "6", items: userData?.name || userData?.user?.name, path: "/information" },

  ];
  const navItemsTutor2 = [
    { id: "2", items: "Dashboard", path: "/dashboard" },
    { id: "5", items: "Meetings", path: "/meetings" },
    { id: "6", items: userData?.name, path: "/dashboard" },
  ];
  const navItemsStudent = [
    { id: "2", items: "Tutor", path: "/all-tutors" },
    { id: "1", items: "Sessions", path: "/sessions" },
    { id: "3", items: "Features" },
    // { id: "4", items: "Qibla", path: "/sd" },
    { id: "5", items: "Prayer Timing", path: "/prayers" },
    // { id: "5", items: "Islamic Calender", path: "/islamic-calender" },
    // { id: "5", items: "Location", path: "/" },
    { id: "6", items: userData?.name, path: "/home" },
  ]
  const navItems = [

    { id: "6", items: "Profile", path: "/home" },

  ];
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleLinkClick = (itemId) => {
    setSelectedLink(itemId);
    handleClose();
  };

  const handleFeaturesMouseEnter = () => {
    setIsFeaturesDropdownVisible(true);
  };

  const handleFeaturesMouseLeave = () => {
    setIsFeaturesDropdownVisible(false);
  };
  const handlePlansMouseEnter = () => {
    setIsPlansDropdownVisible(true);
  };
  const handlePlansMouseLeave = () => {
    setIsPlansDropdownVisible(false);
  };
  const handleProfileMouseEnter = () => {
    setIsProfileDropdownVisible(true);
  };
  const handleProfileMouseLeave = () => {
    setIsProfileDropdownVisible(false);
  };
  return (
    <>
      <ScrollHandler />
      <Navbar
        bg="white"
        expand="xl"
        sticky="top"
        className="main_nav px-4"
        id="navbar"
      >
        <div className="2xl:container container-fluid">
          <Navbar.Brand>
            <Link
              to="/"
              style={{ textDecoration: "none" }}
              className="text_primary"
            >
              {/* <h4 className="text_white mb-0 gilroy-semibold">LOGO</h4> */}
              <img
                src={zikrLogo}
                alt=""
                className="h-[3rem] md:h-[3rem] w-[3rem] rounded-full"
              />
            </Link>
          </Navbar.Brand>
          <div className="relative">
            <SearchRounded
              onClick={toggleModal}
              className="absolute ms-3 text_white md:mt-[10px] xss:-mt-[10px]"
            />
            <input
              type="text"
              style={{ border: "0.6px solid #CFCFCF1D" }}
              className="bg_darksecondary  relative search_input md:block hidden ps-5 pe-3 py-2 rounded-5 text_white"
              placeholder="Search"
              name=""
              id=""
            />
            {/* {isModalOpen && (
        <div
          className="fixed inset-0 bg-transparent flex justify-center mt-8 items-center z-50"
          onClick={() => setIsModalOpen(false)}
        >
          <div
            className="rounded-lg  max-w-md mt-20 relative w-fit"
            onClick={(e) => e.stopPropagation()} // Prevents closing when clicking inside the modal
          >

            <input
              type="text"
              className="w-[] p-2 border rounded-lg"
              placeholder="Search..."
            />
            <div className="absolute right-0 top-0" 
          onClick={() => setIsModalOpen(false)}

            >

           <Trash2 />
            </div>
          </div>
        </div>
      )} */}
          </div>

          <Nav className="ms-auto flex">
            <div className="d-none d-xl-flex items-center gap-1">

              {role === 'student' && isLogin &&
                navItemsStudent.map((item, i) => (
                  <div
                    key={i}
                    className={
                      item?.items === userData?.name
                        ? "text-white nav-item-container"
                        : "nav-item-container text-nowrap"
                    }
                    onMouseEnter={
                      item.items === "Features"
                        ? handleFeaturesMouseEnter
                        : item.items === "Plans"
                          ? handlePlansMouseEnter
                          : item.items === userData?.name
                            ? handleProfileMouseEnter
                            : null
                    }
                    onMouseLeave={
                      item.items === "Features"
                        ? handleFeaturesMouseLeave
                        : item.items === "Plans"
                          ? handlePlansMouseLeave
                          : item.items === userData?.name
                            ? handleProfileMouseLeave
                            : null
                    }
                  >
                    <NavLink
                      className="nav-link text_white px-3 flex items-center gilroy-medium"
                      to={item.path}
                      onClick={() => {
                        handleLinkClick(item.id);
                        setIsProfileDropdownVisible(false);
                      }}
                    >
                      <div className="me-1">
                        {item.items === userData?.name
                          && (

                            <img
                              style={{
                                borderRadius: "50%",
                                width: "40px",
                                height: "40px",

                              }}

                              className=" object-cover" src={userData?.image || profileavatar
                              } alt="" />
                          )}
                      </div>
                      {item.items === "Location" && (
                        <img
                          src={locationicon}
                          style={{
                            height: "18px",
                            width: "auto",
                            display: "inline",
                            marginRight: "4px",
                          }}
                          alt=""
                        />
                      )}
                      {item.items}
                      {item.items === "Features" && <ArrowDropDown />}
                      <div className="ms-2">
                        {item.items === userData?.name
                          && <img src={more} alt="more" />}
                      </div>
                    </NavLink>
                    {item.items === "Features" && isFeaturesDropdownVisible && (
                      <div className="dropdown-menu show">
                        <ul>
                          <li><Link to="/names">Names of Allah</Link></li>
                          <li><Link to="/duas"> Duas</Link></li>
                          <li><Link to="/qibla"> Qibla</Link></li>
                          <li><Link to="/hajj">Hajj & Umrah</Link></li>
                          <li><Link to="/islamic-calender">Islamic Calender</Link></li>
                          <li><Link to="/learn">Learn Quran</Link></li>
                          <li><Link to="/tasbeeh">Tasbeeh</Link></li>
                        </ul>
                      </div>
                    )}
                    {item.items === userData?.name
                      && isProfileDropdownVisible && (
                        <div className="dropdown-menu show">
                          <ul>
                            {isLogin ? (
                              <>
                                <li><Link to="/profile-setting">Setting</Link></li>
                                <li onClick={handleLogout}><Link>Logout</Link></li>
                              </>
                            ) : (
                              <>
                                <li>
                                  <Link
                                    onClick={() => registerRole("student")}
                                    className="whitespace-nowrap"
                                    to="/login"
                                  >
                                    Login as Student
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    onClick={() => registerRole("tutor")}
                                    className="whitespace-nowrap"
                                    to="/login"
                                  >
                                    Login as Tutor
                                  </Link>
                                </li>
                              </>
                            )}
                          </ul>
                        </div>
                      )}
                  </div>
                ))
              }
              {(role === 'tutor' && isLogin && !information) &&
                navItemsTutor.map((item, i) => (
                  <div
                    key={i}
                    className={
                      item?.items === userData?.name
                        ? "text-white nav-item-container"
                        : "nav-item-container text-nowrap"
                    }
                    onMouseEnter={
                      item.items === userData?.name
                        ? handleProfileMouseEnter
                        : null
                    }
                    onMouseLeave={
                      item.items === userData?.name
                        ? handleProfileMouseLeave
                        : null
                    }
                  >
                    <NavLink
                      className="nav-link text_white px-3 flex items-center gilroy-medium"
                      to={item.path}
                      onClick={() => {
                        handleLinkClick(item.id);
                        setIsProfileDropdownVisible(false);
                      }}
                    >
                      <div className="me-1">
                        {item.items === userData?.name && (
                          <img
                            style={{
                              borderRadius: "50%",
                              width: "40px",
                              height: "40px",

                            }}

                            className=" object-cover" src={userData?.image || profileavatar
                            } alt="" />
                        )}
                      </div>

                      {item.items}

                      <div className="ms-2">
                        {item.items === userData?.name && <img src={more} alt="more" />}
                      </div>
                    </NavLink>

                    {item.items === userData?.name && isProfileDropdownVisible && (
                      <div className="dropdown-menu show">
                        <ul>
                          {isLogin ? (
                            <>
                              <li><Link to="/Profile-setting">Setting</Link></li>
                              <li onClick={handleLogout}><Link>Logout</Link></li>
                            </>
                          ) : (
                            <>
                              <li>
                                <Link
                                  onClick={() => registerRole("student")}
                                  className="whitespace-nowrap"
                                  to="/login"
                                >
                                  Login as Student
                                </Link>
                              </li>
                              <li>
                                <Link
                                  onClick={() => registerRole("tutor")}
                                  className="whitespace-nowrap"
                                  to="/login"
                                >
                                  Login as Tutor
                                </Link>
                              </li>
                            </>
                          )}
                        </ul>
                      </div>
                    )}
                  </div>
                ))
              }
              {(role === 'tutor' && isLogin && information) &&
                navItemsTutor2.map((item, i) => (
                  <div
                    key={i}
                    className={
                      item?.items === userData?.name
                        ? "text-white nav-item-container"
                        : "nav-item-container text-nowrap"
                    }
                    onMouseEnter={
                      item.items === userData?.name
                        ? handleProfileMouseEnter
                        : null
                    }
                    onMouseLeave={
                      item.items === userData?.name
                        ? handleProfileMouseLeave
                        : null
                    }
                  >
                    <NavLink
                      className="nav-link text_white px-3 flex items-center gilroy-medium"
                      to={item.path}
                      onClick={() => {
                        handleLinkClick(item.id);
                        setIsProfileDropdownVisible(false);
                      }}
                    >
                      <div className="me-1">
                        {item.items === userData?.name && (
                          <img
                            style={{
                              borderRadius: "50%",
                              width: "40px",
                              height: "40px",

                            }}

                            className=" object-cover" src={userData?.image || profileavatar
                            } alt="" />
                        )}
                      </div>

                      {item.items}

                      <div className="ms-2">
                        {item.items === userData?.name && <img src={more} alt="more" />}
                      </div>
                    </NavLink>

                    {item.items === userData?.name && isProfileDropdownVisible && (
                      <div className="dropdown-menu show">
                        <ul>
                          {isLogin ? (
                            <>
                              <li><Link to="/Profile-setting">Setting</Link></li>
                              <li onClick={handleLogout}><Link>Logout</Link></li>
                            </>
                          ) : (
                            <>
                              <li>
                                <Link
                                  onClick={() => registerRole("student")}
                                  className="whitespace-nowrap"
                                  to="/login"
                                >
                                  Login as Student
                                </Link>
                              </li>
                              <li>
                                <Link
                                  onClick={() => registerRole('tutor')}
                                  className="whitespace-nowrap"
                                  to="/login"
                                >
                                  Login as Tutor
                                </Link>
                              </li>
                            </>
                          )}
                        </ul>
                      </div>
                    )}
                  </div>
                ))
              }
              {role !== 'student' || role !== 'tutor' || (role === 'tutor' && !isLogin) || (role === 'student' && !isLogin) &&
                navItems.map((item, i) => (
                  <div
                    key={i}
                    className={
                      item?.items === "Profile"
                        ? "text-white nav-item-container"
                        : "nav-item-container text-nowrap"
                    }
                    onMouseEnter={
                      item.items === "Features"
                        ? handleFeaturesMouseEnter
                        : item.items === "Plans"
                          ? handlePlansMouseEnter
                          : item.items === "Profile"
                            ? handleProfileMouseEnter
                            : null
                    }
                    onMouseLeave={
                      item.items === "Features"
                        ? handleFeaturesMouseLeave
                        : item.items === "Plans"
                          ? handlePlansMouseLeave
                          : item.items === "Profile"
                            ? handleProfileMouseLeave
                            : null
                    }
                  >
                    <NavLink
                      className="nav-link text_white px-3 flex items-center gilroy-medium"
                      to={item.path}
                      onClick={() => {
                        handleLinkClick(item.id);
                        setIsProfileDropdownVisible(false);
                      }}
                    >
                      <div className="me-1">
                        {item.items === "Profile" && (
                          <img src={profileavatar} alt="" />
                        )}
                      </div>
                      {item.items === "Location" && (
                        <img
                          src={locationicon}
                          style={{
                            height: "18px",
                            width: "auto",
                            display: "inline",
                            marginRight: "4px",
                          }}
                          alt=""
                        />
                      )}
                      {item.items}
                      <div className="ms-2">
                        {item.items === "Profile" && <img src={more} alt="more" />}
                      </div>
                    </NavLink>

                    {item.items === "Profile" && isProfileDropdownVisible && (
                      <div className="dropdown-menu show">
                        <ul>
                          {isLogin ? (
                            <>
                              <li><Link to="/Profile-setting">Setting</Link></li>
                              <li onClick={handleLogout}><Link>Logout</Link></li>
                            </>
                          ) : (
                            <>
                              <li>
                                <Link
                                  onClick={() => registerRole("student")}
                                  className="whitespace-nowrap"
                                  to="/login"
                                >
                                  Login as Student
                                </Link>
                              </li>
                              <li>
                                <Link
                                  onClick={() => registerRole("tutor")}
                                  className="whitespace-nowrap"
                                  to="/login"
                                >
                                  Login as Tutor
                                </Link>
                              </li>
                            </>
                          )}
                        </ul>
                      </div>
                    )}
                  </div>
                ))
              }
              {(role !== 'student' || role !== 'tutor') && !isLogin &&
                navItems.map((item, i) => (
                  <div
                    key={i}
                    className={
                      item?.items === "Profile"
                        ? "text-white nav-item-container"
                        : "nav-item-container text-nowrap"
                    }
                    onMouseEnter={
                      item.items === "Features"
                        ? handleFeaturesMouseEnter
                        : item.items === "Plans"
                          ? handlePlansMouseEnter
                          : item.items === "Profile"
                            ? handleProfileMouseEnter
                            : null
                    }
                    onMouseLeave={
                      item.items === "Features"
                        ? handleFeaturesMouseLeave
                        : item.items === "Plans"
                          ? handlePlansMouseLeave
                          : item.items === "Profile"
                            ? handleProfileMouseLeave
                            : null
                    }
                  >
                    <NavLink
                      className="nav-link text_white px-3 flex items-center gilroy-medium"
                      to={item.path}
                      onClick={() => {
                        handleLinkClick(item.id);
                        setIsProfileDropdownVisible(false);
                      }}
                    >
                      <div className="me-1">
                        {item.items === "Profile" && (
                          <img src={profileavatar} alt="" />
                        )}
                      </div>
                      {item.items === "Location" && (
                        <img
                          src={locationicon}
                          style={{
                            height: "18px",
                            width: "auto",
                            display: "inline",
                            marginRight: "4px",
                          }}
                          alt=""
                        />
                      )}
                      {item.items}
                      <div className="ms-2">
                        {item.items === "Profile" && <img src={more} alt="more" />}
                      </div>
                    </NavLink>

                    {item.items === "Profile" && isProfileDropdownVisible && (
                      <div className="dropdown-menu show">
                        <ul>
                          {isLogin ? (
                            <>
                              <li><Link to="/Profile-setting">Setting</Link></li>
                              <li onClick={handleLogout}><Link>Logout</Link></li>
                            </>
                          ) : (
                            <>
                              <li>
                                <Link
                                  onClick={() => registerRole("student")}
                                  className="whitespace-nowrap"
                                  to="/login"
                                >
                                  Login as Student
                                </Link>
                              </li>
                              <li>
                                <Link
                                  onClick={() => registerRole("tutor")}
                                  className="whitespace-nowrap"
                                  to="/login"
                                >
                                  Login as Tutor
                                </Link>
                              </li>
                            </>
                          )}
                        </ul>
                      </div>
                    )}
                  </div>
                ))
              }
            </div>

            <div className="flex justify-center items-center">
              <Navbar.Toggle
                onClick={handleShow}
                className="ms-[8px] border-0 p-0 mb-1"
              >
                {/* <img src={menuicon} alt='' className='w-3/4' /> */}
                <MenuIcon />
              </Navbar.Toggle>
            </div>
          </Nav>
          <Nav>
            <Offcanvas
              placement="top"
              show={show}
              scroll
              onHide={handleClose}
              className="pb-3 min-h-[16rem] bg-[#aa8555] w-full visible"
            >
              <div className="flex justify-center">
                <div>

                  <Link
                    to="/"
                    style={{ textDecoration: "none" }}
                    className="text_primary"
                  >
                    {/* <h4 className="text_white mb-0 gilroy-semibold">LOGO</h4> */}

                  </Link>
                </div>
              </div>
              <div className="flex justify-between px-6">
                {/* <img src={deal_Logo} alt="" className='h-[2rem]' /> */}
                <img
                  src={zikrLogo}
                  alt=""
                  className="h-[3rem] md:h-[3rem] mt-2 w-[3rem] rounded-full"
                />
                <Offcanvas.Header closeButton>
                </Offcanvas.Header>
              </div>
              <Nav className="gap-2 flex items-center  w-full justify-center">
                {navItemsStudent.map((item, i) => (
                  <NavLink
                    key={i}
                    className={`text-black gilroy-medium mb-1 rounded-[10px] px-4`}
                    to={item.path}
                    onClick={() => handleLinkClick(item.id)}
                  >
                    {item.items}
                    {item.items === "Features" && isFeaturesDropdownVisible && (
                      <div className="dropdown-menu show">
                        <ul className="">
                          <li><Link to="/names">Names of Allah</Link></li>
                          <li><Link to="/duas"> Duas</Link></li>
                          <li><Link to="/qibla"> Qibla</Link></li>
                          <li><Link to="/hajj">Hajj & Umrah</Link></li>
                          <li><Link to="/islamic-calender">Islamic Calender</Link></li>
                          <li><Link to="/learn">Learn Quran</Link></li>
                          <li><Link to="/tasbeeh">Tasbeeh</Link></li>
                        </ul>
                      </div>
                    )}
                  </NavLink>
                ))}
              </Nav>
              {/* <div className="d-flex justify-center items-center w-full flex-col mt-2 gap-2">
                <button
                  onClick={() => setopen(true)}
                  className="rounded-5 bg_primary px-4 w-fit py-2 gilroy-bold hover:bg-[#59c4c452] text_white hover:text-[#59c4c4]"
                >
                  GET A DEMO
                </button>
              </div> */}
            </Offcanvas>
          </Nav>
        </div>
      </Navbar>
      {/* <RequestModal modal={open} setmodal={setopen} /> */}
    </>
  );
};

export default NavHeader;
