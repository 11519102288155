import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { store } from './components/redux/store';
import { Provider } from 'react-redux';
import { notification, Button } from 'antd';
import { BrowserRouter } from 'react-router-dom';
import { praying } from './components/icons/icon';
import NamazReminder from './components/common/namazReminder';

const root = ReactDOM.createRoot(document.getElementById('root'));

// Function to show the Namaz reminder
const showNamazReminder = (namazTime) => {
  notification.info({
    message: 'Upcoming Namaz',
    description: `The next Namaz is at ${namazTime}.`,
    placement: 'bottomRight',
    duration: 0,
    style: {
      color: '#aa8555',
    }

  });
};

root.render(
  <Provider store={store}>
    <React.StrictMode>
      <BrowserRouter>
        <div className='relative'>
          <App />
          {/* Toggler Icon */}
          <div
            className='fixed bottom-5 right-5 cursor-pointer  ' // Adjust position as needed
          >
          
            <NamazReminder />
          </div>
        </div>
      </BrowserRouter>
    </React.StrictMode>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
