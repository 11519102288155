
import axios from "axios";
export const axiosInstance = axios.create({
  baseURL: "https://api.aladhan.com/v1/",
});
axiosInstance.interceptors.request.use(
  (config) => {

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
export const axiosInstanceQuran = axios.create({
  baseURL: "https://api.quran.com/api/v4/"
});
axiosInstanceQuran.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
export const axiosInstanceApi = axios.create({
  // old and official url -----
  // baseURL: "https://api.zikr.ae/api",
  // for temporary use due to server issues----
  baseURL: "https://api.zikr.ae/api",

});
axiosInstanceApi.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("zikr-web-token");
    console.log(token, "...token")
    if (token) {
      config.headers["x-auth-token"] = token;
      config.headers["Content-Type"] = "application/json";
    }
    return config
  },
  (error) => {
    console.log(error, 'er')
    // message.error(error.message)
    return Promise.reject(error);
  }
);
export const axiosInstanceApifile = axios.create({
  baseURL: "https://api.zikr.ae/api",
});
axiosInstanceApifile.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("zikr-web-token");
    if (token) {
      config.headers["x-auth-token"] = token;
    }
    config.headers["Content-Type"] = "multipart/form-data";
    return config
  },
  (error) => {
    console.log(error, 'er')
    // message.error(error.message)
    return Promise.reject(error);
  }
);