/* eslint-disable no-unused-vars */
import "bootstrap/dist/css/bootstrap.min.css";
import { Suspense, useEffect } from "react";
import { Spinner, ThemeProvider } from "react-bootstrap";
import { useSelector } from "react-redux";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useLocation } from "react-router-dom";
import "tailwindcss/tailwind.css";
import "./App.css";
import "./app.scss";
import UserLayout from "./components/layout/layout";
import NavHeader from "./components/navHeader/navHeader";
import Routing from "./components/routes/routes";
import "./components/style/main.css";
import "./components/style/main.scss";
import { CircularProgress } from "@mui/material";
import axios from "axios";
function App() {
  const location = useLocation();
  const theme = useSelector((state) => state.themeDart.theme);
  const excludedPaths = [
    "/login",
    "/register",
    "/send-code",
    "/verify-code",
    "/forgot-password",
    "/verify-otp",
    "/register-tutor",
  ];
  useEffect(() => {
    document.documentElement.setAttribute("data-bs-theme", theme);
    document.documentElement.setAttribute("data-theme", theme);
  }, [theme]);
  useEffect(() => {
    global.BASEURL = `http://192.168.18.134:5200/api`;
    global.BASEURL2 = `http://192.168.18.134:5200`;
    global.TOKEN = window.localStorage.getItem("react_template_token");
    window.scroll(0, 0);
  }, [location]);
  // const token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NmNjYTQwYTI1MzJiYTJiNjQ3ZTc1ZjkiLCJ0eXBlIjoic3R1ZGVudCIsImxhbmciOiJlbmdsaXNoIiwiaWF0IjoxNzI5Njg0NTI4fQ.AnSYzhc9-EDSg_h_c3ZLfvMl4hgE2tH6uzS89tdv9MY";
  // const fetchData = async () => {
  //   console.log('hellooooooooo')
  //   try {
  //     const [users, totalCount] = await Promise.all([
  //       axios.get('https://api.zikr.ae/api/users/trainers', { headers: { 'Content-Type': 'application/json', 'x-auth-token': token } }),
  //       axios.get('https://api.zikr.ae/api/session/student/pending/', { headers: { 'Content-Type': 'application/json', 'x-auth-token': token } }),
  //     ]);
  //     if (users) {
  //       console.log("Users data:", users);  // Output the users data
  //     }

  //     if (totalCount) {
  //       console.log("Total count:", totalCount);  // Output the total count data
  //     }
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //   }
  // };

  // fetchData();
  return (
    <>
      <ThemeProvider theme={{ mode: theme }}>
        <UserLayout>
          {!excludedPaths.includes(location.pathname) && <NavHeader />}
          <Suspense
            fallback={
              <div className="w-100 vh-100 main_app d-flex justify-content-center align-items-center">
                <CircularProgress style={{ color: '#aa8555' }} size={22} className='text_white ' />
              </div>
            }
          >
            <Routing />
          </Suspense>
        </UserLayout>
      </ThemeProvider>
    </>
  );
}

export default App;
