import { notification } from 'antd';
import React, { useEffect, useState } from 'react';
import { axiosInstance } from '../api/axiosInstance';
import { praying } from '../icons/icon';
import { useSelector } from 'react-redux';
const NamazReminder = () => {
    const isLogin = useSelector((state) => state.auth.isLogin);

    const [namazTimes, setNamazTimes] = useState([]);
    const [nextPrayer, setNextPrayer] = useState(null);
    const [remainingTime, setRemainingTime] = useState('');
    useEffect(() => {
        const fetchNamazTimes = async () => {
            navigator.geolocation.getCurrentPosition(async (position) => {
                const { latitude, longitude } = position.coords;
                const response = await axiosInstance.get(`timings?latitude=${latitude}&longitude=${longitude}`);
                const timings = response.data.data.timings;
                const now = new Date();
                const nextPrayer = getNextPrayer(timings, now);
                setNamazTimes(timings);
                setNextPrayer(nextPrayer);
                calculateRemainingTime(nextPrayer?.time, now);
            })
        };
        fetchNamazTimes();
        const interval = setInterval(() => {
            if (nextPrayer) {
                const now = new Date();
                calculateRemainingTime(nextPrayer.time, now);
            }
        }, 60000);

        return () => clearInterval(interval);
    }, []);

    const getNextPrayer = (timings, now) => {
        const prayerNames = ["Fajr", "Dhuhr", "Asr", "Maghrib", "Isha"];
        for (const prayer of prayerNames) {
            const prayerTime = new Date(`${now.toDateString()} ${timings[prayer]}`);
            if (prayerTime > now) {
                return { name: prayer, time: prayerTime };
            }
        }
        return null;
    };

    const calculateRemainingTime = (prayerTime, now) => {
        const timeDiff = prayerTime - now;
        if (timeDiff > 0) {
            const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
            setRemainingTime(`${minutes} minutes`);
        } else {
            setRemainingTime('0 minutes');
        }
    };

    const showNamazReminder = () => {
        if (nextPrayer) {
            notification.info({
                message: `Upcoming Namaz: ${nextPrayer.name}`,
                description: `The next Namaz is at ${nextPrayer.time.toLocaleTimeString()} (in ${remainingTime})`,
                placement: 'bottomRight',
                duration: 0,
                style: {
                    color: '#aa8555',
                }

            });
        }
    };

    return (
        <>
            {
                isLogin && (

                    <div

                        className='fixed bottom-5 right-5 scroltop cursor-pointer p-3 rounded-full bg-[#eeb23a]'
                        onClick={showNamazReminder}
                    >
                        <img src={praying} alt='' width="50px" />
                    </div>
                )
            }
        </>
    );
};

export default NamazReminder;
