import { Outlet, Navigate } from 'react-router-dom';
import { useAuth } from './useAuth';
import { useSelector } from "react-redux";

function StudentRoutes() {
    const role = window.localStorage.getItem('role');
    if (role === 'tutor') {
        return <Navigate to="/" />;
    }

    return <Outlet />;
}

export default StudentRoutes;
