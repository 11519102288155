import { createSlice } from '@reduxjs/toolkit';

export const bookSession = createSlice({
    name: 'booking',
    initialState: {
        bookingData: {},
    },
    reducers: {
        setBookingData: (state, action) => {
            state.bookingData = action.payload;
        },

    },
});
export const { setBookingData } = bookSession.actions;

export default bookSession.reducer;