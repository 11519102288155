import { Outlet, Navigate } from 'react-router-dom';
import { useAuth } from './useAuth';
import { useSelector } from "react-redux";

function TutorRoutes() {
    const role = window.localStorage.getItem('role');
    if (role === 'student') {
        return <Navigate to="/dashboard" />;
    }

    return <Outlet />;
}

export default TutorRoutes;
