import { message, Result } from 'antd'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { axiosInstanceApi } from '../api/axiosInstance'

const SuccessPage = () => {
  const navigate = useNavigate()
  const bookingData_ = useSelector((state => state.booking.bookingData))
  // console.log(bookingData, 'bookingData', bookingData)

  useEffect(() => {
    const bookingData = {
      bookedDate: bookingData_.bookedDate,
      bookedSlot: bookingData_.bookedSlot,
      message: bookingData_.message,
      sessionType: bookingData_.sessionType,
      to_id: bookingData_.to_id,
      amount: 1000,

    }
    if (bookingData) {
      axiosInstanceApi.post('/session/create', bookingData).then((response) => {
        console.log(response)
        if (response.success) {
          message.success('Successfully created your session with the tutor')
        }
      }).catch(() => { });
    }
  }, [bookingData_])
  return (
    <div className='flex items-center justify-center h-screen  px-2 '>
      <div style={{ boxShadow: '0 10px 30px rgba(170, 133, 85, 0.5)', }} className="card text-white md:max-w-[700px] w-auto border-0 min-h-[100px] bg-[#16181b] h-auto py-7 px-4 rounded-sm">
        <Result
          className='text-white'
          status="success"
          title={<span style={{ color: '#aa8555' }}>Successfully created your session with the tutor</span>}
          subTitle={<span style={{ color: '#aa8555' }}>Your session has been successfully booked. Please wait for the tutor to confirm. You will receive further details via email.</span>}
          extra={[
            <button onClick={() => navigate('/sessions')} className='reuse_btn' type="button" key="console">
              View Sessions
            </button>,
            <button onClick={() => navigate('/booking-form')} className='px-3 py-2 md:mt-0 sm:mt-4 rounded-full bg-white text-[#aa8555]' key="buy">Book Another Session</button>,
          ]}
        />
      </div>

    </div>
  )
}

export default SuccessPage
